import React from 'react';
import { useSelector } from 'react-redux';
import variables from '../../../redux/variables';
import RecentSearch from 'components/feature-components/auto-suggest-recent-search/index.jsx';
import AutoCompleteResultIcon from '../auto-suggest-icon-1729161479640/index.jsx';
import AutoCompleteResultTitle from '../auto-suggest-title-1729079232762/index.jsx';
import AutoCompleteResultMetadata from '../auto-suggest-metadata-1729160256524/index.jsx';
import AutoLearningSuggestion from 'components/feature-components/auto-suggest-auto-learning/index.jsx';
import { tabIndexes} from '../../../constants/a11y';
import StaticStrings from 'StaticStrings';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
const Suggest = (props) => {
  try {
    const { t } = useTranslation();
    let { comment, comments, granularity, selectedCommunity, askQuestion, askQuestionUrl, changeNoResultFound, communitySuggestions, changeTotalResults, changeUsers, changePrivateMessage } = props
    let urlOpensInNewTab = props.urlOpensInNewTab;
    let currentSearchString = props.currentSearchString || props.data;
    let dataType = props.dataType;
    const autocompleteResult = useSelector((state) => state.autocomplete);
    /**
     *  autocomplete gza function
     */
    const resultOpenNewTab = (item, index) => {
      gza('search', {
        'searchString': variables.autocompleteCallVariables.searchString,
        'result_count': autocompleteResult.result.hits.length,
        'page_no': 0,
        'uid': variables.autocompleteCallVariables.uid,
        'filter': [],
        'conversion': [{
          rank: (index + 1),
          url: item['href'],
          subject: item['highlight']['TitleToDisplayString'][0] || '',
          es_id: item['sourceName'] + '/' + item['objName'] + '/' + encodeURIComponent(item['_id']),
          sc_analytics_fields: item['trackAnalytics']
        }]
      });
    };
    return (
      <div lang={variables.searchCallVariables.langAttr} aria-label={autocompleteResult && autocompleteResult.result && autocompleteResult.result.hits && autocompleteResult.result.hits.length + ' ' + `${t(StaticStrings.suggestions_found)}`} aria-live="assertive" aria-atomic="true" tabIndex={tabIndexes.tabIndex_0} className="su__autoSuggestion su__autosearchapp-suggestion su__w-100 su__bg-white su__sm-shadow su__position-absolute su__zindex-2">
        <div id="my_div" className="su__suggestions-box su__minscroller">
          <div className="auto-suggestion">
            { autocompleteResult && autocompleteResult.recentSearchHistory && autocompleteResult.recentSearchHistory.length != 0 && <RecentSearch currentSearchString={currentSearchString} component={props.component} dataType={dataType}/>}
            {granularity == "user" && autocompleteResult && autocompleteResult.length !== 0 && <div className='su__suggestions-list su__bg-gray-hover su__d-flex font-14 su__text-black su__f-bold su__font-italic'><div className='suggestedContent'><span> {changeUsers}</span></div></div>}
            {granularity == "notes" && autocompleteResult && autocompleteResult.length !== 0 && <div className='recommendedResult'><div><span> {changePrivateMessage}</span></div></div>}
            {granularity != "user" && granularity != "notes" && autocompleteResult && autocompleteResult.result && autocompleteResult.result.hits.length != 0 && <div className='su__suggestions-list su__bg-gray-hover su__d-flex font-14 su__text-black su__f-bold su__font-italic'><div className='suggestedContent'><span>{communitySuggestions}</span><span> {changeTotalResults + " (" + autocompleteResult.result.total + ")"}</span></div></div>}
            { autocompleteResult.result && variables.autocompleteCallVariables.searchString && autocompleteResult.result.hits.length != 0 && granularity != 'user' && granularity != "notes" &&
              React.Children.toArray(autocompleteResult.result.hits.map((item, index) => (
                <div key={uuid()} data-key={index} tabIndex="-1" className={`su__suggestions-list su__bg-gray-hover su__d-flex font-14 su__text-black  ${((currentSearchString === ( item && item.highlight && item.highlight.TitleToDisplayString && item.highlight.TitleToDisplayString[0] || item.href)) && dataType =='autosuggest') ? 'su__highlight_result' : ''}`}>
                      <AutoCompleteResultIcon item={item}/>
                      <div className="su__suggestions-txt">
                        <AutoCompleteResultTitle item={item} index={index} resultOpenNewTab={resultOpenNewTab} urlOpensInNewTab={urlOpensInNewTab}/>
                        <AutoCompleteResultMetadata comment={comment} comments={comments} item={item}/>
                       </div>
                </div>
              )))} 
               {(granularity == "user" || granularity == "message") && autocompleteResult && autocompleteResult.result && autocompleteResult.result.hits.length != 0 && variables.autocompleteCallVariables.searchString &&
                      autocompleteResult.result.hits.map((autosuggestItem) => (
                    <>
                        <div tabIndex="-1" class={`su__suggestions-list su__bg-gray-hover su__d-flex font-14 su__text-black su_users_list ${(currentSearchString === autosuggestItem.value) ? 'su__highlight_result' : ''}`}>
                            <a href={autosuggestItem.value.url}>
                                <span class="su_label-text su__flex-vcenter su__autocomplete-icon-margin-custom" dangerouslySetInnerHTML={{ __html: autosuggestItem.data[0]}} />
                            </a>
                        </div>

                    </>
                ))
            }
             {autocompleteResult && autocompleteResult.smartAggregations && autocompleteResult.smartAggregations.length &&
                <div className={`su__product-sugt-row su__minscroller su__product-length-${autocompleteResult && autocompleteResult.smartAggregations && autocompleteResult.smartAggregations.length}`} style={{ position: (autocompleteResult.result.hits.length) ? "absolute" : "inherit"}}>
                  <AutoLearningSuggestion autocompleteResult={autocompleteResult}/> 
                </div>
              }
              {autocompleteResult && autocompleteResult.result && autocompleteResult.result.hits.length == 0 && <div className='noResultFound su_p-10'> {changeNoResultFound}</div>}
                {autocompleteResult && autocompleteResult.result && <div className='su__ask-question su__bg-gray-hover su__d-flex font-14 su__text-black' ><div className='postToCommunity'><a className='postQuestion' href={askQuestionUrl}> {askQuestion}</a></div></div>}
                {/*{(granularity == "user" || granularity == "notes") && autocompleteResult && autocompleteResult.length == 0 && <div className='noResultFound'> {changeNoResultFound}</div>}*/}
                {/*{(granularity == "user" || granularity == "notes") && autocompleteResult && <div className='su__ask-question su__d-flex font-14 su__text-black'><div className='postToCommunity'><a className='postQuestion' href={askQuestionUrl}> {askQuestion}</a></div></div>}*/}
          </div> 
      </div>
      </div>
    );
  } catch (e) {
    console.log('Error in auto-suggest component', e);
    return (<div></div>);
  }
};



export default Suggest;