/* eslint-disable no-self-assign */
import React, { Fragment } from 'react';
import { v4 as uuid } from 'uuid';

const AutoCompleteResultMetadata = (props) => {
    try {
        let { item, comment, comments } = props;
        const stripHtml = (html) => {
            html = html.toString();
            html = html.replace(new RegExp("<span class='highlight'>", 'g'), '_____');
            html = html.replace(new RegExp('</span>', 'g'), '__.__._');
            html = html.replace(/<\/?\w*\b[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&lt;|&gt;/ig, '');
            html = html.replace(/\s+/g, ' ');
            html = html.replace(new RegExp('_____', 'g'), '<span class="highlight">');
            html = html.replace(new RegExp('__.__._', 'g'), '</span>');
            return html;
        };
        return (
            <Fragment>
                {React.Children.toArray((item.autosuggestData.map(autosuggestItem => {
                    autosuggestItem.key === 'Description' && autosuggestItem.value[0].trim() != '' && (
                        autosuggestItem.value[0] = autosuggestItem.value[0].length > 100 ? autosuggestItem.value[0].substring(0, 200) + '...' : autosuggestItem.value[0]
                    );
                    return (
                        <Fragment>
                            {autosuggestItem.key === 'Description' && autosuggestItem.value[0].trim() != '' ?
                                <div className="su__suggestion-desc su__line-height-n font-11 su__color-lgray">
                                    <span className="su__color-gray" dangerouslySetInnerHTML={{ __html: stripHtml(autosuggestItem.value[0]) }} />
                                </div> : null}
                        </Fragment>
                    );
                })))}
                {React.Children.toArray((item.autosuggestData.map(autosuggestItem => {
                    autosuggestItem.key === 'Summary' && autosuggestItem.value[0].trim() != '' && (
                        autosuggestItem.value[0] = autosuggestItem.value[0].length > 100 ? autosuggestItem.value[0].substring(0, 200) + '...' : autosuggestItem.value[0]
                    );
                    return (
                        <Fragment>
                            {autosuggestItem.key === 'Summary' && autosuggestItem.value[0].trim() != '' ?
                                <div className="su__suggestion-desc su__line-height-n font-11 su__color-lgray">
                                    <span className="su__color-gray" dangerouslySetInnerHTML={{ __html: stripHtml(autosuggestItem.value[0]) }} />
                                </div> : null}
                        </Fragment>
                    );
                })))}

                {React.Children.toArray((item.autosuggestData.map(meta => {
                    meta.key == 'Metadata' && (
                        meta.value[0] = meta.value[0].length > 100 ? meta.value[0].substring(0, 200) + '...' : meta.value[0]
                    );
                    return (
                        <Fragment>
                            {meta.key === 'Metadata' ?
                                <span className="su__subdata font-12 su__pr-3 su__line-height-n">
                                    {meta.key == 'Metadata' ? <span className="su-bullets su__pr-1">&#8226;</span> : null}
                                    {meta.key == 'Metadata' ? <span>{meta.value[0]}</span> : null}
                                </span>
                                : null}
                        </Fragment>
                    );
                })))}

                {React.Children.toArray((item.autosuggestData.map(meta => {
                    meta.key === 'Reply Count' && (
                        meta.value[0] = meta.value[0].length > 100 ? meta.value[0].substring(0, 200) + '...' : meta.value[0]
                    );
                    return (
                        <Fragment>
                            {meta.key === 'Reply Count' ?
                                <span className="su__subdata font-12 su__pr-3 su__line-height-n">
                                    <span className="su-bullets su__d-xl-none su__d-lg-inline-block">&#8226;</span>
                                    <span className="su__pr-1 su__line-height-n" dangerouslySetInnerHTML={{ __html: meta.value[0] ? meta.value[0] : '0 Comments' }} />
                                    {/*{meta.key === 'Reply Count' && meta.value[0] == '0' ? <span>{comment}</span> : null}*/}
                                    {meta.key === 'Reply Count' && meta.value[0] == '1' ? <span>{comment}</span> : null}
                                    {meta.key === 'Reply Count' && meta.value[0] > '1' ? <span>{comments}</span> : null}
                                </span>
                                : null}
                        </Fragment>
                    );
                })))}

                {React.Children.toArray((item.autosuggestData.map(meta => {
                    meta.key == 'Created Date' && (
                        meta.value[0] = meta.value[0].length > 100 ? meta.value[0].substring(0, 200) + '...' : meta.value[0]
                    );
                    meta.key == 'Board' && (
                        meta.value[0] = meta.value[0].length > 100 ? meta.value[0].substring(0, 200) + '...' : meta.value[0]
                    );
                    return (
                        <Fragment>
                            {meta.key === 'Created Date' || meta.key === 'Board' ?
                                <span className="su__subdata font-12 su__pr-3 su__line-height-n">
                                    <span className="su-bullets su__pr-1">&#8226;</span>
                                    {meta.key == 'Created Date' ? <span>{meta.value[0].toString().split(" ")[0]}</span> : null}
                                    {meta.key == 'Board' ? <span>{item.boardName ? item.boardName : ''}</span> : null}
                                </span>
                                : null}
                        </Fragment>
                    );
                })))}
                {/*{item.autosuggestData.filter((autosuggestItem) => autosuggestItem.key !== 'Title' && autosuggestItem.value && autosuggestItem.value.toString().length !==0 )
          .reverse().map((autosuggestItem, index) => {     
            return (
              <div key={uuid()} className={`su__suggestion-desc su__align_flex su__line-height-n su__font-11 su__color-lgray su__suggest${autosuggestItem.key.split(' ').join('')}`}>
                <span className="su__description-truncate" dangerouslySetInnerHTML={{ __html: autosuggestItem.value }}></span>
              </div>
            );
          }
          )}*/}
            </Fragment>
        );
    } catch (e) {
        console.log('Error in AutoCompleteResultMetadata component', e);
        return (<div></div>);
    }
};

export default React.memo(AutoCompleteResultMetadata);