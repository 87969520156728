/* eslint-disable no-undef */
// import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/merge';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/timer';
import variables from './variables';
import { search } from './ducks';
import utilityMethods from './utilities/utility-methods';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { searchUrlReq } from './searchClientTypes';
import searchtrack from '../analytics/search';

/**
 * Make search call to backend on the basis of search client type.
 * @param {} queryPassed
 */
const htmlDecode = function (input) {
  let e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};

const searchEpic = (action$) =>
  action$.pipe(
    ofType(search.start().type),
    mergeMap(async ({ queryPassed }) => {
      queryPassed['getAutoTunedResult'] = true;
      queryPassed['getSimilarSearches'] = true;
      variables.searchCallVariables.searchString = queryPassed.searchString;
      window.onload = function() {
        if(variables.searchCallVariables.searchString == "") {
          variables.selectSortBy = "";
        } else {
          variables.selectSortBy = "_score";
        }
      }
      if (variables.controllingVariables.firstTimeLoad && !variables.controllingVariables.processing) {
        utilityMethods.importQueryParamsToVariables();
      }
      variables.controllingVariables.firstTimeLoad = false;
      if (!variables.onpopstate) {
        utilityMethods.updateURLParams();
      }
      variables.onpopstate = false;
      variables.searchCallVariables = JSON.parse(JSON.stringify(variables.searchCallVariables));
      variables.controllingVariables.processing = true;
      let filterAggregations = variables.searchCallVariables.aggregations;
      if(filterAggregations.length) {
        filterAggregations = filterAggregations.filter(function(filter, index) {
          if(filter.filter) {
            return filterAggregations[index];
          } else {
            return false;
          }
        });
      }

      queryPassed.mergeSources = filterAggregations.length == 0;
      queryPassed.versionResults = Boolean(queryPassed.mergeSources);
      queryPassed.suCaseCreate = Boolean(variables.searchCallVariables.suCaseCreate);
      let start = new Date();
      let paramsUrlReq = await searchUrlReq(variables.searchClientType, queryPassed);
      try {
        const response = await fetch(paramsUrlReq.url, paramsUrlReq.req);
        variables.controllingVariables.processing = false;
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const resp = response;
        const results = await resp.json();
        if(!window.user && window.su_utm){
          window.user = window.su_utm;                                                                    
          GzAnalytics.setUser(window.user);
        }
        for(let i of results.aggregationsArray){       
          for(let j of i.values){
            if(j.Contentname){
              j.ContentnameFrontend= htmlDecode(j.Contentname);   
            }
          }
        }    
        variables.searchResposeTimer = new Date() - start;
        searchtrack(results); /** search analytics tracking */
        if (variables.searchClientType === 2 || variables.searchClientType === '2') {
          if (results.result.hits.length !== 0) {
            var localStorage;
            localStorage = window.localStorage;
            var postIds = [];
            var dataInLS = [];
            var postIdsFromLS = [];
            var dataFromLS = [];
            var liveCountArray = [];
            var dataToResult;
            // postids from ls
            const currentDate = new Date;
            if (localStorage.getItem("liveCounts") && JSON.parse(localStorage.getItem("liveCounts")).liveCountData) {
              (JSON.parse(localStorage.getItem("liveCounts")).liveCountData);
              if(currentDate.getTime() < JSON.parse(localStorage.getItem("liveCounts")).expiry) {
                liveCountArray = localStorage.getItem("liveCounts");
                liveCountArray = JSON.parse(liveCountArray)
                liveCountArray.liveCountData.forEach(element => {
                postIdsFromLS.push(element.id);
                dataFromLS.push(element);
              });
              }
              else {
                localStorage.removeItem("liveCounts")
              }
            }
            //
            for (let i = 0; i < results.result.hits.length; i++) {
              if (results.result.hits[i] && results.result.hits[i].boardName) {
                try {
                  if(postIdsFromLS && postIdsFromLS.length > 0) {
                    if (!postIdsFromLS.includes(results.result.hits[i]._id)) {
                      postIds.push(JSON.stringify(results.result.hits[i]._id));
                    }
                    else {
                      dataInLS.push(JSON.stringify(results.result.hits[i]._id));
                    }
                  }
                  else {
                    postIds.push(JSON.stringify(results.result.hits[i]._id));
                  }  
                }
                catch(err) {
                  console.log(err)
                }
              } else {
                if (i === results.result.hits.length) {
                  return search.success(results);
                }
              }	
            }
            if (postIds && postIds.length > 0) {
              var getSearchResultsLiveCountsUrl = window.su_community_live_count + '?postIds=' + postIds;
			  console.log(`getSearchResultsLiveCountsUrl-- ${getSearchResultsLiveCountsUrl}`);
              var getSearchResultsLiveCountsReq = {
                method: 'GET'
              }
              await fetch(getSearchResultsLiveCountsUrl, getSearchResultsLiveCountsReq).then(function(response) {
                return response.json();
              }).then(function(data) {
                var finalLSData = [];
                console.log("data from custom endpoint hit",data);
                finalLSData.push(...data.items);
                data.items.forEach(element => {
                  dataToResult = {"kudos": element.kudos.sum.weight, "views": element.metrics.views, "replies": element.replies.count};
                  for (let l = 0; l < results.result.hits.length; l++) {
                    if(results.result.hits[l]._id === element.id) {
                      results.result.hits[l].liveCounts = {};
                      results.result.hits[l].liveCounts = dataToResult;
                    }
                  }
                });
                
                if (localStorage.getItem("liveCounts") && JSON.parse(localStorage.getItem("liveCounts")).liveCountData.length > 0) {
                  finalLSData.push(...JSON.parse(localStorage.getItem("liveCounts")).liveCountData)
                  localStorage.setItem("liveCounts", JSON.stringify({"liveCountData":finalLSData , "expiry":new Date(currentDate.getTime() + (10 * 60 * 1000)).getTime()}));
                }
                else {
                  localStorage.setItem("liveCounts", JSON.stringify({"liveCountData":finalLSData , "expiry":new Date(currentDate.getTime() + (10 * 60 * 1000)).getTime()}));
                }
              }).catch((error) => {
                console.error('Error:', error);
              });
              if (dataInLS && dataInLS.length > 0) {
                dataFromLS.forEach(element => {
                  if (dataInLS.includes(element.id)) {
                    dataToResult = {"kudos": element.kudos.sum.weight, "views": element.metrics.views, "replies": element.replies.count};
                    for (let j = 0; j < results.result.hits.length; j++) {
                      if(results.result.hits[j]._id === element.id) {
                        results.result.hits[j].liveCounts = {};
                        results.result.hits[j].liveCounts = dataToResult;
                      }
                    }    
                  }
                });
              }
            }
            else {
              dataFromLS.forEach(element => {
                  dataToResult = {"kudos": element.kudos.sum.weight, "views": element.metrics.views, "replies": element.replies.count};
                  for (let k = 0; k < results.result.hits.length; k++) {
                    if(results.result.hits[k]._id === element.id) {
                      results.result.hits[k].liveCounts = {};
                      results.result.hits[k].liveCounts = dataToResult;
                    }
                  }
              });
            }
            console.log("results.result.hits before return", results.result.hits)
            return search.success(results);
          } else {
            return search.success(results);
          }
        } else {
          return search.success(results);
        }
      }
      catch (err) {
        if (variables.searchClientType == 2) {
          window.location.href = 't5/forums/searchpage/tab/message/search?standard=true&q='+queryPassed.searchString;
        } else {
          return search.fail(err);
        }
      }
    })
  );
export default searchEpic;