/* eslint-disable no-inner-declarations */
/* eslint-disable no-undef */
import variables from './variables';
import utilityMethods from './utilities/utility-methods';

let jwtBearer; 
let BASEURL;
let initializeAuthPromise;

/**
 * search call and recomendations to backend on the basis of search client type.
 * @param {} queryPassed
 */

/** Refs */
const memoryRefs = [];

function getEmail () {
  return new Promise(async (resolve) => {
    let email = '';
    let check = 0;
    const clientType = variables.searchClientType;

    switch (clientType){
      case 28:
        window.onVanillaReady(function (vanilla) {
          const { email = '' } = vanilla?.getCurrentUser();
          GzAnalytics.setUser(email);
          if(email){
            variables.searchCallVariables['email'] = window.su_utm;
            variables.autocompleteCallVariables['email'] = window.su_utm;
            resolve(email);
          }
        });
        break;

      case 11:
        const intervalId = setInterval(() => {
          check++;
          email = HelpCenter && HelpCenter.user && HelpCenter.user.email;
          if (email) {
            clearInterval(intervalId);
            resolve(email);
          } else if (check > 6) {
            clearInterval(intervalId);
          } 
        }, 30 * 100);
        break;

      case 12:
      case 16:
        await client.get('currentUser').then(function (user) {
          email = user.currentUser.email;
          resolve(email);
        });
        break;

    }
  });
}

(async function setEmail() {
  const clientType = variables.searchClientType;
  switch(clientType){
    case 21:
      let getLoggedInUser = new GlideAjax('x_312362_c_a_su.getLoggedInUser');
      getLoggedInUser.addParam('sysparm_name', 'getUserEmail');
      getLoggedInUser.getXML(callback);

      function callback(result) {
        let data = result.responseXML.documentElement.getAttribute('answer');
        suGlobals.loggedInUser = data;
        if (data) {
          GzAnalytics.setUser(data);
          variables.searchCallVariables['email'] = window.su_utm;
          variables.autocompleteCallVariables['email'] = window.su_utm;
        }
      }
      break;
    case 11:
    case 12:
    case 16:
      const fetchEmail = await getEmail();
      if (fetchEmail) {
          GzAnalytics.setUser(fetchEmail);
          variables.searchCallVariables['email'] = window.su_utm;
          variables.autocompleteCallVariables['email'] = window.su_utm;
      } else {
          GzAnalytics.setUser('');
      }
      break;
    /** vanilla platform */
    case 28:
      utilityMethods.clearIntervalsAndTimeouts(memoryRefs); // cleanup
      if (variables.searchClientType == 28 && gdn?.meta?.roleToken != '') {
        const fetchEmail = await getEmail();
        if (fetchEmail) {
          memoryRefs.push(utilityMethods.withInterval(getEmail, 60 * 100)); // trigger interval
          utilityMethods.clearIntervalsAndTimeouts(memoryRefs); // cleanup
        }
      } else {
        GzAnalytics.setUser('');
      }
      break;
    default:
      let emailInterval = setInterval(function() {
        if (window.user) {
          if(window.isGuestUser) window.user = '';
          clearInterval(emailInterval);
          GzAnalytics.setUser(window.user);
          variables.searchCallVariables['email'] = window.su_utm;
          variables.autocompleteCallVariables['email'] = window.su_utm;
        }
      }, 509);
  }
})();


/** Get Auth JWT token */
async function getAuthToken(url) {
  try {
    const response = await fetch(url);
    if (response.ok) {
      let authtoken = await response.text();
      const searchClientType = variables.searchClientType;
      try {
        switch (searchClientType) {
          case 14:
          case 15:
          case 17:
            try {
              authtoken = JSON.parse(authtoken);
            } catch (error) {
              console.error('[ error ]', error);
            }
            jwtBearer = authtoken.token;
            GzAnalytics.setUser(authtoken.email);
            variables.searchCallVariables['email'] = window.su_utm;
            variables.autocompleteCallVariables['email'] = window.su_utm;
            break;
          case 23:
            let tokenHigher = decodeURIComponent(authtoken) && decodeURIComponent(authtoken).split('authtoken=') && decodeURIComponent(authtoken).split('authtoken=')[1];
            jwtBearer = tokenHigher;
            break;
          case 24:
            window.email = window.su__aem_useremail;
            jwtBearer = authtoken;
        }
      } catch (error) {
        console.error('[ error ]', error);
      }
    } else {
      console.error('Failed to fetch JWT token:', response.status, response.statusText);
    }
  } catch (error) {
    console.error('[ error ]', error);
  }
}

const getPlatformUrl = async (searchClientType) => {
  let check = 0;
  return new Promise((resolve, reject) => {
    let checkPlatformUrl = setInterval(async () => {
      check++;
      switch (searchClientType) {
        case 14:
          if (document.getElementById('wpBaseUrl') && document.getElementById('wpBaseUrl').value) {
            clearInterval(checkPlatformUrl);
            await getAuthToken(document.getElementById('wpBaseUrl').value + '/wp-json/search-unify/v1/search_jwt');
            resolve();
          }
          break;
        case 15:
          if (typeof Drupal != 'undefined' && Drupal.settings && Drupal.settings.basePath) {
            clearInterval(checkPlatformUrl);
            BASEURL = window.location.origin + Drupal.settings.basePath + 'search-unify/v1';
            resolve();
          }
          break;
        case 17:
          if (typeof Drupal != 'undefined' && drupalSettings.path && drupalSettings.path.baseUrl) {
            clearInterval(checkPlatformUrl);
            await getAuthToken(window.location.origin + drupalSettings.path.baseUrl + 'search-unify/v1/search_jwt');
            resolve();
          }
          break;
        default:
          if (check > 350) {
            clearInterval(checkPlatformUrl);
            reject(new Error(`Can't read platform dependent variable. Something went wrong!!`));
          }
      }
    }, 30);
  });
};

const initializeAuth = async () => {
  if (!initializeAuthPromise) {
    initializeAuthPromise = (async () => {
      if ([14, 15, 17].includes(variables.searchClientType)) {
        await getPlatformUrl(variables.searchClientType);
      } else if ([23, 24].includes(variables.searchClientType)) {
        await getAuthToken(scConfiguration.jwt_href);
        setInterval(() => {
          getAuthToken(scConfiguration.jwt_href);
        }, parseInt(scConfiguration.jwt_expiry) || 300000);
      }
    })();
  }
  return initializeAuthPromise;
};

initializeAuth();

const getPayloadWithWildcard = (queryPassed) => {
  const payload = JSON.parse(JSON.stringify(queryPassed));
  let wildCardSearch = !!sessionStorage.getItem('toggleWildcardSearch');
  if(wildCardSearch && queryPassed.searchString.charAt(0) !== '#'){ // if session storage is true
    payload.searchString = '#' + queryPassed.searchString;
  }
  return JSON.stringify(payload);
};

export async function savedResultReq(searchClientType, queryPassed) {
  let instanceName = variables.searchClientProps.instanceName;
  let url = '';
  let req = {};
  switch(searchClientType){
    case 6:
    case 11:
    case 12:
    case 16:
    case 19:
    case 25:
    case 26:
    case 28:
      let searchEndpoint = '/search/getSavedResultsForIds';
      url = `${instanceName}${searchEndpoint}`;
      req = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(queryPassed)
      };
      break;
    
    case 23:
      await initializeAuth();
      queryPassed.authtoken = localStorage.getItem('authtoken');
      queryPassed.higherlogic = true;
      queryPassed.HLAuthToken = document.cookie.split('HLAuthToken=')[1].split(';')[0] || '';
      let searchEndpointHL = '/search/SUSavedResultsForIds';

      url = `${instanceName}${searchEndpointHL}`;
      req = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(queryPassed)
      };
      break;

    case 24:
    case 14:
    case 17:
      await initializeAuth();
      searchClientType == 14 || searchClientType == 17 ? queryPassed.JWTSecureGroup1 = true : queryPassed.aem = true;
      searchEndpoint = '/search/SUSavedResultsForIds';
      url = `${instanceName}${searchEndpoint}`;

      req = {
        method: 'POST',
        headers: {
          'authorization': 'bearer ' + jwtBearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(queryPassed)
      }
        break;
      
      
    case 10:
    case 1:
      //Jive
      break;
      
    case 9:
    case 3:
      let searchEndpointSFInternal = '/search/SUSavedResultsForIds';
      url = `${instanceName}${searchEndpointSFInternal}`;
      req = {
        method: 'POST',
        headers: {
          'authorization': 'bearer ' +  window.jwtBearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(queryPassed)
      };
      break;
    case 2:
      queryPassed.recordIds = JSON.stringify(queryPassed.recordIds);

      const searchParams = Object.keys(queryPassed).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(queryPassed[key]);
      }).join('&');
      let getSearchResultsEndpoint = window.su_savedResults_path;
      url = getSearchResultsEndpoint;
      req = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: searchParams
      };
      queryPassed.recordIds = JSON.parse(queryPassed.recordIds);
      break;
    case 15:
      let searchResultByPost = '/SUSavedResultsForIds';
      url = BASEURL + searchResultByPost;
      req = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(queryPassed)
      };

      break;
    case 18: // need to check
      break;
    case 21: // need to check
      break;
    case 20: // need to check
      break;
  }
  return { url, req };
}

export async function searchUrlReq(searchClientType, queryPassed) {
  let instanceName = variables.searchClientProps.instanceName;
  let url = '';
  let req = {};
  let searchEndpoint;
  switch(searchClientType){
    case 6:
    case 11:
    case 12:
    case 16:
    case 19:
    case 25:
    case 26:
    case 28:

      if(searchClientType == 16) queryPassed.searchString = queryPassed.searchString=='' ? window.caseSubject : queryPassed.searchString;
      searchEndpoint = '/search/searchResultByPost';
      url = `${instanceName}${searchEndpoint}`;
      req = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: getPayloadWithWildcard(queryPassed)
     };
     break;

    case 23:
      await initializeAuth();
      queryPassed.authtoken = jwtBearer;
      queryPassed.higherlogic = true;
      queryPassed.HLAuthToken = document.cookie.split('HLAuthToken=')[1].split(';')[0] || '';
      let searchEndpointHL = '/search/SUSearchResults';

      url = `${instanceName}${searchEndpointHL}`;
      req = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: getPayloadWithWildcard(queryPassed)
      };
      break;
    case 24:
    case 14:
    case 17:
      await initializeAuth();
      searchClientType == 14 || searchClientType == 17 ? queryPassed.JWTSecureGroup1 = true : queryPassed.aem = true;
      searchEndpoint = '/search/SUSearchResults';
      url = `${instanceName}${searchEndpoint}`;
  
      req = {
        method: 'POST',
        headers: {
          'authorization': 'bearer ' + jwtBearer,
          'Content-Type': 'application/json'
        },
        body: getPayloadWithWildcard(queryPassed)
      };
      break;

    case 10:
    case 1:
      break;

    case 9:
    case 3:
      let searchEndpointSFInternal = '/search/SUSearchResults';
      url = `${instanceName}${searchEndpointSFInternal}`;
      req = {
        method: 'POST',
        headers: {
          'authorization': 'bearer ' + window.jwtBearer,
          'Content-Type': 'application/json'
        },
        body: getPayloadWithWildcard(queryPassed)
      };
      break;

    case 2:
    variables.pageRefresh=true;
    let checkVideo = false;
        if(queryPassed.aggregations.length  > 0) {
            for (var i = 0; i < queryPassed.aggregations.length; i++) {
                if (queryPassed.aggregations[i].type == 'rootCategoryId') {
                    queryPassed.aggregations.splice(i, 1);
                } else if(queryPassed.aggregations[i].type == "_index" && queryPassed.aggregations[i].filter[0] == "alteryx_prod_videos") {
                    checkVideo = true;
                }
            }
        }

        if(!checkVideo) {
            if (window.localStorage.getItem("language") == 'en') {
                queryPassed.aggregations.push({ "type": "rootCategoryId", "filter": ["external"] });
            } else if (window.localStorage.getItem("language") == 'ja') {
                queryPassed.aggregations.push({ "type": "rootCategoryId", "filter": ["external-ja"] });
            } else if (window.localStorage.getItem("language") == 'es') {
                queryPassed.aggregations.push({ "type": "rootCategoryId", "filter": ["external-es"] });
            } else if (window.localStorage.getItem("language") == 'fr') {
                queryPassed.aggregations.push({ "type": "rootCategoryId", "filter": ["external-fr"] });
            } else if (window.localStorage.getItem("language") == 'de') {
                queryPassed.aggregations.push({ "type": "rootCategoryId", "filter": ["external-de"] });
            } else if (window.localStorage.getItem("language") == 'pt') {
                queryPassed.aggregations.push({ "type": "rootCategoryId", "filter": ["external-pt-br"] });
            }
        }

        if(queryPassed.searchString == "" && !variables.selectSortBy) {
            queryPassed.sortby = variables.searchCallVariables.sortby = "post_time";
        } else if (queryPassed.searchString != "" && !variables.selectSortBy) {
            variables.selectSortBy = variables.searchCallVariables.sortby = "_score";
        } else  {
            queryPassed.sortby = variables.searchCallVariables.sortby = variables.selectSortBy;
        }
      queryPassed = getPayloadWithWildcard(queryPassed);
      queryPassed = JSON.parse(queryPassed);
      queryPassed.aggregations = JSON.stringify(queryPassed.aggregations);
      if(queryPassed.pagingAggregation && typeof queryPassed.pagingAggregation != 'string') {
        queryPassed.pagingAggregation = JSON.stringify(queryPassed.pagingAggregation);
      }
      queryPassed.searchString = encodeURIComponent(queryPassed.searchString);
      const searchParams = Object.keys(queryPassed).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(queryPassed[key]);
      }).join('&');
      var getSearchResultsEndpoint = window.su_community_path;
      url = getSearchResultsEndpoint;
      req = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: searchParams
      };
      queryPassed.aggregations = JSON.parse(queryPassed.aggregations);
      queryPassed.searchString = decodeURIComponent(queryPassed.searchString);
      queryPassed.aggregations = JSON.stringify(queryPassed.aggregations);

            if(window.su_community_path){
                var getSearchResultsEndpoint = window.su_community_path;
                if (document.getElementsByClassName('search-granularity') && document.getElementsByClassName('search-granularity')[0] && document.getElementsByClassName('search-granularity')[0].value.split("|")[1] == 'user' && variables.users) {
                    getSearchResultsEndpoint = "/t5/community/categorypage.searchformv32.usersearchfield.usersearchfield:autocomplete?t:ac=category-id/external/q-p/Y2F0ZWdvcnkuaWQ6ZXh0ZXJuYWw.&t:cp=search/contributions/page&q=" + variables.autocompleteCallVariables.searchString + "&limit=1000&timestamp=1569855432076&searchContext=undefined"
                } else if (document.getElementsByClassName('search-granularity') && document.getElementsByClassName('search-granularity')[0] && document.getElementsByClassName('search-granularity')[0].value.split("|")[1] == 'notes') {
                    getSearchResultsEndpoint = "/t5/community/categorypage.searchformv32.notesearchfield.notesearchfield:autocomplete?t:ac=category-id/external/q-p/Y2F0ZWdvcnkuaWQ6ZXh0ZXJuYWw.&t:cp=search/contributions/page&q=" + variables.autocompleteCallVariables.searchString + "&limit=1000&timestamp=1569664177689&searchContext=notes%7Cnotes";
                } 
        
                url = getSearchResultsEndpoint;
                req = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    body: searchParams
                }
            }
    queryPassed.aggregations = JSON.parse(queryPassed.aggregations);
      break;

    case 15:
      let BASEURL;
      let searchResultByPost = '/searchResultByPost';
      url = BASEURL + searchResultByPost;
      req = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: getPayloadWithWildcard(queryPassed)
      };
      break;

    case 18:
      let queryToBePassed = JSON.stringify(queryPassed);
      queryToBePassed = queryToBePassed.replace(/\"/g, '\'');
      let finalQueryPassed = { 'agre': queryToBePassed };
      let serverURL = location.protocol + '//' + location.host;
      let query = 'ser_Custom_Action';
      url = serverURL + '/api/data/v8.2/' + query;
      req = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: getPayloadWithWildcard(finalQueryPassed)
      };
      break;

    case 21:  // serviceNow
     break;

    case 20:
      req = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(queryPassed)
      };
      break;
  }
  return { url, req };
}

export async function recomendationsUrlReq(searchClientType, data) {
  // http request for different searchClientType
  let instanceName = variables.searchClientProps.instanceName;
  let url = '';
  let queryPassed = {
    'uid': data.uid,
    'searchString': variables.searchCallVariables.searchString,
    'sid': typeof _gr_utility_functions !== "undefined"? _gr_utility_functions.getCookie('_gz_taid') : '',
    'language': localStorage.getItem('language') || 'en',
    'useremail': variables.searchCallVariables.email || ''
  };

  if ([1,6,10,11,12,19,25,26,28].includes(searchClientType)) {
    queryPassed['accessToken'] = data.accessToken;
  }
  if(data.isRecommendationsWidget){
    queryPassed['recommendationType'] = 2;
  }

  let req = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(queryPassed)
  };

  switch (searchClientType){

    case 2:
      url = window.su_recommendations;
      const searchParams = Object.keys(queryPassed).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(queryPassed[key]);
      }).join('&');
      req = {
        ...req,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: searchParams
      };
      break;

    case 1:
    case 6:
    case 10:
    case 11:
    case 12:
    case 19:
    case 25:
    case 26:
    case 28:
      url = instanceName + '/ai/getRecommendedResult';
      break;

    case 23:
      await initializeAuth();
      queryPassed.authtoken = jwtBearer;
      queryPassed.higherlogicRecomm = true;
      queryPassed.HLAuthToken = document.cookie.split('HLAuthToken=')[1].split(';')[0] || '';
      let searchEndpointHL = '/search/getRecommendedResult';

      url = `${instanceName}${searchEndpointHL}`;
      req = {
        ...req,
        body: JSON.stringify(queryPassed)
      };

      break;

    case 15:
      url = BASEURL + '/ai/getRecommendedResult';
      break;

    case 16:
    case 18:
      url = instanceName + '/ai/getRecommendedResult';
      break;


    case 9:
    case 3:
      queryPassed.orgId = window.orgId;
      url = instanceName + '/ai/authSURecommendation';
      req = {
        ...req,
        headers: {
          ...req.headers,
          'authorization': 'bearer ' +  window.jwtBearer
        },
        body: JSON.stringify(queryPassed)
      };
      break;

    case 24:
    case 14:
    case 17:
      await initializeAuth();
      searchClientType == 14 || searchClientType == 17 ? queryPassed.JWTSecureGroup1 = true : queryPassed.aem = true;
      url = instanceName + '/ai/authSURecommendation';
      req = {
        ...req,
        headers: {
          ...req.headers,
          'authorization': 'bearer ' + jwtBearer,
        },
        body: JSON.stringify(queryPassed)
      }
      break;

  }

  return { url, req };
}

export async function sugptUrlReq(searchClientType, variables) {
  let body;
  let headers = new Headers();
  let url = variables.searchClientProps.instanceName + variables.searchClientProps.gptEndPoint;
  body = {
    query: variables.searchCallVariables.searchString,
    description: variables.gptContext,
    streaming: variables.gptStreaming,
    llm: true,
    separator : variables.STREAM_DELIMITER,
    articles : variables.gptLinks
  };

  headers.append('uid', variables.searchCallVariables.uid);
  headers.append('search-client-type', variables.searchClientType);
  headers.append('search-id', _gza_analytics_id);
  try {
    headers.append('taid-device', typeof _gr_utility_functions !== "undefined"? _gr_utility_functions.getCookie('_gz_taid') : '',);
    headers.append('sid-session', typeof _gr_utility_functions !== "undefined" ? _gr_utility_functions.getCookie('_gz_sid') : '',);
  } catch (error) {
    console.error('[ error ]', error);
  }
  
  switch(searchClientType){
    case 6:
    case 11:
    case 12:
    case 16:
    case 19:
    case 25:
    case 26:
    case 28:
      headers.append('Content-Type', 'application/json');
      headers.append('token', variables.searchCallVariables.accessToken);
      body = JSON.stringify(body);
      break;
    case 23:
      // Higher Logic
      body = JSON.stringify(body);
      break;
    case 14:
    case 17:
    case 24:
      // Wordpress || Drupal10 || AEM
      await initializeAuth();
      body = JSON.stringify(body);
      headers.append('Content-Type', 'application/json');
      headers.append('token', jwtBearer);
      break;
    case 10:
    case 1:
      //Jive
      break;
    case 3:
    case 9:
      // su_vf_community || su_vf_internal
      body = JSON.stringify(body);
      headers.append('Content-Type', 'application/json');
      headers.append('token', window.jwtBearer);
      break;
    case 2:
      headers.append('search-client-type', variables.searchClientType);
      headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      body.articles = JSON.stringify(body.articles);
      url = window.su_gpt_path;
      body.description = encodeURIComponent(variables.gptContext);
      body.uid = variables.searchCallVariables.uid;
    
      const searchParams = Object.keys(body).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);
      }).join('&');
    
      body = searchParams;
      break;
    case 15:
      body.streaming = false;
      body = JSON.stringify(body);
      headers.append('token', variables.searchCallVariables.accessToken);
      headers.append('Content-Type', 'application/json');
      url = BASEURL + '/su-gpt';
      break;
    case 18:
      // microsoft dynamics
    case 21:
      // serviceNow
    case 20:
      // hosted
      break;
    }
  let req = {
    method: 'POST',
    body: body,
    headers: headers,
    redirect: 'follow'
  };
  return { url, req };
}

export default { searchUrlReq, recomendationsUrlReq, sugptUrlReq };
