import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import variables from './redux/variables';
import { autocomplete } from './redux/ducks';
import useComponentVisible from './event-handler/event';

import AutoCompleteSearchIcon from 'components/custom-components/search-box-search-icon-1729227611123/index.jsx';
import AutoCompleteClearIcon from 'components/section-components/search-box-clear-icon/index.jsx';
import AutoCompleteLoading from 'components/section-components/search-box-loading/index.jsx';

import AutoSuggestion from 'components/custom-components/auto-suggest-search-app-1729157126334/index.jsx';
import AutoCompleteResultIcon from 'components/custom-components/auto-suggest-icon-1729161479640/index.jsx';
import AutoCompleteResultTitle from 'components/custom-components/auto-suggest-title-1729079232762/index.jsx';
import AutoCompleteResultMetadata from 'components/custom-components/auto-suggest-metadata-1729160256524/index.jsx';
import RecentSearch from 'components/feature-components/auto-suggest-recent-search/index.jsx';
import dataFormater from './function-library/dataFormatter';

const AutocompleteApp = () => {

    const dispatch = useDispatch();
    let autoCompleteResult = useSelector((state) => { return state.autocomplete; });
    let viewedResult = undefined;
    if (autoCompleteResult.length !== 0) {
        viewedResult = dataFormater(autoCompleteResult)?.viewedResult;
    }
    /** inputValue reference to DOM input element of autocomplete box */
    const inputValue = useRef(null);
    let { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);
    /** 
     * showClearIcons state to manage show/hide clear icon 
     * true - Show clear icon
     * false - hide clear icon
     */
    const [showClearIcons, setShowClearIcons] = useState();

    /** 
     * showAutoCompleteResult state to manage show/hide autocomplete result dropdown
     * true - show autocomplete result dropdown
     * false- hide autocomplete result dropdown
    */
    const [showAutoCompleteResult, setShowAutoCompleteResult] = useState();

    const [prevIdCall, setPrevIdCall] = useState();

    /**
     * changeSearchDataOnArrowKey & previousAction to manage 
     * iterate over autocomplete result using ↓ and ↑ keyboard keys
     */
    const resultsPerPage = Math.max(0, Math.floor(variables.autocompleteCallVariables.resultsPerPage || 0));
    const [changeSearchDataOnArrowKey, setChangeSearchDataOnArrowKey] = useState(Array(resultsPerPage).fill(null).map((_, i) => i));
    const [previousAction, setPreviousAction] = useState('');
    let [openInNewTab, setOpenInNewTab] = useState(null);
    let [granularity, setGranularity] = useState('');

    /** To Keep autocomplete result open on scroll , change keepAutoCompleteResultOpenOnScroll to true  */
    variables.keepAutoCompleteResultOpenOnScroll = false;

    /** This Object can be modified conditionally to set Search API  Url 
     *  Blank Url means default Search Urls is getting used 
     */
    variables.userDefinedAutoCompleteSearchUrl = {
        url: '',
        req: {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(variables.autocompleteCallVariables)
        }
    };

    /** Setting up default result per page to 10 */
    variables.autocompleteCallVariables.resultsPerPage = autoCompleteResult && autoCompleteResult.searchClientSettings ? Number(autoCompleteResult.searchClientSettings.autoComplete) : 10;

    /**
     * searchAppisPresentOnSamePage - To check if Search App and Autocomplete App present on same page
     * if Both apps are present on same page , we can establish communication b/w them using below script in autocomplete.html file
     * https://<- instance name -> /resources/search_clients_custom/<- uid -> /communication.js
     */
    let searchAppisPresentOnSamePage = document.getElementById('search-box-search') ? true : false;

    /**
     * settings.searchBoxPlaceholder - Autocomplete App Search box placeholder ( no language support in standard code)
     * settings.redirectionLink - search page url 
     * settings.instantSearch - When Search App and Autocomplete Apps are present on same page with communication
     * established  , on every key press in autocomplete box ,updating the search query string in search app also along with results
     * without enter key
     *  
     */
    let settings = {
        searchBoxPlaceholder: 'Search here',
        bothAppOnSamePage: searchAppisPresentOnSamePage,
        autocompleteSearchbox: document.getElementById('search-box-autocomplete'),
        instantSearch: searchAppisPresentOnSamePage && (autoCompleteResult.searchClientSettings?.autoCompleteInstant == 1),
        // redirectionLink : autoCompleteResult.searchClientSettings?.redirectionUrl ? autoCompleteResult.searchClientSettings?.redirectionUrl : ''
        redirectionLink: window.scConfiguration?.redirection_url || 't5/forums/searchpage/tab/message/search#!/search'
    };

    // Customization - alteryx
    let selectedCommunity = "alteryx_prod_community";
    if (window.location.pathname.includes("alteryx-io")) {
        selectedCommunity = "alteryx_prod_community_stage"
    }
    let language = "";
    var granularityValue, filter;
    useEffect(() => {
        if (document.getElementsByClassName('search-granularity') && document.getElementsByClassName('search-granularity')[0]) {
            setGranularity(document.getElementsByClassName('search-granularity')[0].value.split("|")[1]);
            filter = document.getElementsByClassName('search-granularity')[0].value.split("|")[0];
            if (granularity == 'community') {
                window.localStorage.setItem("languageFilter", "1");
            } else {
                window.localStorage.setItem("languageFilter", "0");
            }
        }
    }, []);

    useEffect(() => {
        variables.searchSource = 'autocomplete';
        variables.autocompleteCallVariables.searchString = inputValue.current.value;
        if (granularity == 'user') {
            variables.users = true;
        }
        if (variables.autocompleteCallVariables.searchString) {
            setIsComponentVisible(true);
            dispatch(autocomplete.start(variables.autocompleteCallVariables));
        }
    }, [granularity]);

    const [placeholder, setPlaceholder] = useState('Search the Community');
    var placeholdertext = setInterval(function () {
        if (document.getElementsByClassName('search-input')) {
            var arr = document.getElementsByClassName('search-input')
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].className.indexOf('lia-js-hidden') == -1 && window.getComputedStyle(arr[i]).display !== 'none') {
                    setPlaceholder(arr[i].placeholder || 'Search here');
                    clearInterval(placeholdertext)
                }
            }
        }
    })
    if (document.getElementsByClassName("select-dropdown-modify")[0]) {
        document.getElementsByClassName("select-dropdown-modify")[0].addEventListener('click', function () {
            //  dispatchSearchAction()
            setTimeout(function () {
                var arr1 = document.getElementsByClassName('search-input')
                for (var i = 0; i < arr1.length; i++) {
                    if (arr1[i].className.indexOf('lia-js-hidden') == -1 && window.getComputedStyle(arr1[i]).display !== 'none') {
                        setPlaceholder(arr1[i].placeholder);
                    }
                }
            })

            setGranularity(document.getElementsByClassName('search-granularity')[0].value.split("|")[1]);
            filter = document.getElementsByClassName('search-granularity')[0].value.split("|")[0];
            if (granularity == 'community') {
                window.localStorage.setItem("languageFilter", "1");
            } else {
                window.localStorage.setItem("languageFilter", "0");
            }

        });
    }
    if (granularity) {
        setTimeout(function () {
            granularityValue = document.getElementsByClassName('search-granularity')[0].value;
            setGranularity(document.getElementsByClassName('search-granularity')[0].value.split("|")[1])
            filter = document.getElementsByClassName('search-granularity')[0].value.split("|")[0];

            if (LITHIUM.CommunityJsonObject.Page.name != 'SearchPage' && LITHIUM.CommunityJsonObject.CoreNode.id != 'search') {
                if (granularity == 'community') {
                    window.localStorage.setItem("languageFilter", "1");
                } else {
                    window.localStorage.setItem("languageFilter", "0");
                }
            }
        }, 1000);
    }
    //Language Filter for lithium
    if (LITHIUM.CommunityJsonObject.Page.name != 'SearchPage' && LITHIUM.CommunityJsonObject.Page.name != 'CrmSupportPage') {
        if (LITHIUM.CommunityJsonObject.CoreNode.ancestors.length > 1) {
            if (LITHIUM.CommunityJsonObject.CoreNode.ancestors[LITHIUM.CommunityJsonObject.CoreNode.ancestors.length - 2].id.indexOf("-") != -1) {
                var val = LITHIUM.CommunityJsonObject.CoreNode.ancestors[LITHIUM.CommunityJsonObject.CoreNode.ancestors.length - 2].id;
                var valIndex = val.lastIndexOf('-');
                var languageFilter = val.substring(valIndex + 1);
                language = languageFilter;
            } else {
                language = "en";
            }
        } else {
            if (LITHIUM.CommunityJsonObject.CoreNode.id.indexOf("-") != -1) {
                var val = LITHIUM.CommunityJsonObject.CoreNode.id;
                var valIndex = val.lastIndexOf('-');
                var languageFilter = val.substring(valIndex + 1);
                language = languageFilter;
            } else {
                language = "en";
            }
        }
        if (language == "br") {
            language = "pt";
        }
    }
    else {
        language = LITHIUM.CommunityJsonObject.User.settings["profile.language"];
        if (language == 'pt-br') {
            language = 'pt';
        }
    }
    window.localStorage.setItem("language", language);

    if (LITHIUM.CommunityJsonObject.Page.name == 'PostPage') {
        if (LITHIUM.CommunityJsonObject.User.settings["profile.language"] == 'pt-br') {
            window.localStorage.setItem("language", "pt");
            language = "pt";
        } else {
            window.localStorage.setItem("language", LITHIUM.CommunityJsonObject.User.settings["profile.language"]);
            language = LITHIUM.CommunityJsonObject.User.settings["profile.language"];
        }
    }
    /**
     * focus on searchbar
     */
    //document.getElementsByClassName('search-icon')[0].addEventListener('click', function(){
    //    setTimeout(function () {
    //        inputValue.current.focus();
    //    }, 1000);
    //})


    if (language == "en") {
        var askQuestion = "Ask a Question";
        var communitySuggestions = "Community";
        var changePrivateMessage = "Private Messages";
        var changeUsers = "Users";
        var changeNoResultFound = "No search results found.";
        var changeTotalResults = "Total Results";
        var Reply = "Comments";
        var askQuestionUrl = "t5/forums/postpage/choose-node/true/interaction-style/forum/board-id/designer-discussions";
        var comment = "Comment";
        var comments = "Comments";
    } else if (language == "ja") {
        var askQuestion = "質問する";
        var communitySuggestions = "コミュニティ";
        var changePrivateMessage = "プライベートメッセージ";
        var changeUsers = "ユーザー";
        var changeNoResultFound = "検索結果は見つかりませんでした";
        var changeTotalResults = "トータル結果";
        var Reply = "コメント";
        var comment = "コメント";
        var comments = "コメント";
        var askQuestionUrl = "/t5/forums/postpage/board-id/discussions-ja";
    } else if (language == "pt") {
        var askQuestion = "Faça uma pergunta";
        var communitySuggestions = "Comunidade";
        var changePrivateMessage = "Mensagens privadas";
        var changeUsers = "Comercial";
        var changeNoResultFound = "Nenhum resultado de pesquisa encontrado.";
        var changeTotalResults = "Total de Resultados";
        var Reply = "Comentários";
        var comment = "Comentário";
        var comments = "Comentários";
        var askQuestionUrl = "/t5/forums/postpage/board-id/discussions-pt-br";
    } else if (language == "fr") {
        var askQuestion = "Poser une question";
        var communitySuggestions = "Communauté";
        var changePrivateMessage = "Messages privés";
        var changeUsers = "Utilisatrices";
        var changeNoResultFound = "Aucun résultat n''a été trouvé pour la recherche.";
        var changeTotalResults = "Total des résultats";
        var Reply = "commentaires";
        var comment = "Commentaire";
        var comments = "Commentaires";
        var askQuestionUrl = "/t5/forums/postpage/board-id/discussions-fr";
    } else if (language == "de") {
        var askQuestion = "Stelle eine Frage";
        var communitySuggestions = "Community";
        var changePrivateMessage = "Private Nachricht";
        var changeUsers = "Benutzer";
        var changeNoResultFound = "Keine Suchergebnisse gefunden.";
        var changeTotalResults = "Gesamtergebnisse";
        var Reply = "Bemerkungen";
        var comment = "Kommentar";
        var comments = "Kommentare";
        var askQuestionUrl = "/t5/forums/postpage/board-id/discussions-de";
    } else if (language == "es") {
        var askQuestion = "Haz una pregunta";
        var communitySuggestions = "Comunidad";
        var changePrivateMessage = "mensajes privados";
        var changeUsers = "Los usuarios";
        var changeNoResultFound = "No se han encontrado resultados de búsqueda.";
        var changeTotalResults = "Resultados totales";
        var Reply = "Comentarios";
        var comment = "Comentario";
        var comments = "Comentarios";
        var askQuestionUrl = "/t5/forums/postpage/board-id/discussions-es";
    }
    var aggResults;
    if (granularity == 'community') {
        aggResults = [{ "type": "_type", "filter": ["knowledge__kav", "idea", "occasion", "forum", "blog", "tkb"] }, { "type": "rootCategoryId", "filter": ["external"] }];
        //aggResults = [{"type":"indexName","filter":[selectedCommunity]},{"type":"rootCategoryId","filter":["external"]}];
    } else if (granularity == 'category') {
        variables.autocompleteCallVariables.category = LITHIUM.CommunityJsonObject.CoreNode.id
        if (LITHIUM.CommunityJsonObject.CoreNode.ancestors.length == 1) {
            aggResults = encodeURIComponent(encodeURIComponent('[{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"rootCategoryId","filter":["' + LITHIUM.CommunityJsonObject.CoreNode.id + '"]}]'));
        }
        else {
            if (LITHIUM.CommunityJsonObject.CoreNode.nodeType == 'category') {
                aggResults = encodeURIComponent(encodeURIComponent('[{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"parentCategoryName","filter":["' + encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.shortTitle) + '"]}]'));
            } else {
                aggResults = encodeURIComponent(encodeURIComponent('[{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"parentCategoryName","filter":["' + encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.ancestors[0].shortTitle) + '"]}]'));
            }
        }
        // } else if (granularity == 'tkb' || granularity == 'tkb-board') {
        //     aggResults = encodeURIComponent(encodeURIComponent('[{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"_type","filter":["tkb"]}]'));
    } else if (granularity == 'tkb' || granularity == 'tkb-board') {
        aggResults = encodeURIComponent(encodeURIComponent('[{"type":"indexName","filter":["1_29_salesforce_knowledge"]}]'));
    } else if (granularity.split("-")[1] == 'board') {
        aggResults = encodeURIComponent(encodeURIComponent('[{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"boardName","filter":["' + encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.shortTitle) + '"]},{"type":"_type","filter":["' + encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.conversationStyle) + '"]},{"type":"rootCategoryId","filter":["' + encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.ancestors.slice(-2)[0].id) + '"]}]'));
    }
    else if (granularity == 'grouphub') {
        aggResults = encodeURIComponent(encodeURIComponent('[{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"grouphub_short_title","filter":["' + encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.shortTitle) + '"]}]'));
    }
    else if (granularity == 'authorMessages') {
        var userName = document.getElementsByClassName('search-granularity')[0][document.getElementsByClassName('search-granularity')[0].selectedIndex].innerText;
        //var userName = document.getElementsByClassName('search-granularity')[0].selectedOptions[0].innerText;
        aggResults = encodeURIComponent(encodeURIComponent('[{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"author","filter":["' + userName + '"]}]'));
    }
    variables.autocompleteCallVariables.aggregations = aggResults ? (granularity != 'community' ? JSON.parse(decodeURIComponent(decodeURIComponent(aggResults))) : aggResults) : variables.autocompleteCallVariables.aggregations;

    /**Redriection on search Icon click and enter press*/
    const redirection = (searchString) => {
        let searchVariable = searchString ? searchString : variables.autocompleteCallVariables.searchString;
        /** 
         *  - redirect from autocomplete app to search app 
         *  - Set Redirection url from admin panel , it is basically search app url
         *  - on Enter press in autocomplete app / search icon , redirection happen to mentioned
         *    url along with search Sting
         *  - no redirection if search App exist on same page
        */
        if (!settings.bothAppOnSamePage) {
            if (granularity == 'community') {
                window.localStorage.setItem("languageFilter", "1");
            } else {
                window.localStorage.setItem("languageFilter", "0");
            }
            if (LITHIUM.CommunityJsonObject.Page.name == 'SearchPage' || LITHIUM.CommunityJsonObject.CoreNode.id == 'search') {
                window.localStorage.setItem('reloadPage', '1');
            } else {
                window.localStorage.setItem('reloadPage', '0');
            }
            var aggResults = '';
            if (granularity == 'user') {
                window.location = "/t5/forums/searchpage/tab/message/search#!/search?searchString=" + encodeURIComponent(searchVariable) + "&from=0&sortby=_score&orderBy=desc&pageNo=1&aggregations=%255B" + aggResults + "%255D&uid=a7f8ff43-b37c-11e9-ad2e-06908fe445c6&resultsPerPage=10&exactPhrase=&withOneOrMore=&withoutTheWords=&pageSize=10&tab=user";
            } else if (granularity == 'notes') {
                window.location = "/t5/forums/searchpage/tab/message/search#!/search?searchString=" + encodeURIComponent(searchVariable) + "&from=0&sortby=_score&orderBy=desc&pageNo=1&aggregations=%255B" + aggResults + "%255D&uid=a7f8ff43-b37c-11e9-ad2e-06908fe445c6&resultsPerPage=10&exactPhrase=&withOneOrMore=&withoutTheWords=&pageSize=10&tab=notes";
            } else {
                if (granularity == 'community') {
                    localStorage.setItem('AllContent', 'true');
                    aggResults = encodeURIComponent(encodeURIComponent('{"type":"_type","filter":["knowledge__kav","idea","occasion","forum","blog","tkb"]}'));
                    // aggResults = encodeURIComponent(encodeURIComponent('{"type":"indexName","filter":["' + selectedCommunity + '"]}'));
                } else if (granularity == 'category') {
                    variables.autocompleteCallVariables.category = LITHIUM.CommunityJsonObject.CoreNode.id
                    if (LITHIUM.CommunityJsonObject.CoreNode.ancestors.length == 1) {
                        aggResults = encodeURIComponent(encodeURIComponent('{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"rootCategoryId","filter":["' + LITHIUM.CommunityJsonObject.CoreNode.id + '"]}'));
                    } else {
                        if (LITHIUM.CommunityJsonObject.CoreNode.nodeType == 'category') {
                            aggResults = encodeURIComponent(encodeURIComponent(encodeURIComponent('{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"parentCategoryName","filter":["' + encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.shortTitle) + '"]}')));
                        } else {
                            aggResults = encodeURIComponent(encodeURIComponent('{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"parentCategoryName","filter":["' + encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.ancestors[0].shortTitle) + '"]}'));
                        }
                    }
                } else if (granularity == 'tkb') {
                    // aggResults = encodeURIComponent(encodeURIComponent('{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"_type","filter":["tkb"]}'));
                    aggResults = encodeURIComponent(encodeURIComponent('{"type":"indexName","filter":["1_29_salesforce_knowledge"]}'));
                } else if (granularity.split("-")[1] == 'board') {
                    aggResults = encodeURIComponent(encodeURIComponent('{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"boardName","filter":["' + encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.shortTitle) + '"]},{"type":"_type","filter":["' + encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.conversationStyle) + '"]}'));
                }
                else if (LITHIUM.CommunityJsonObject.CoreNode.nodeType == 'grouphub') {
                    aggResults = encodeURIComponent(encodeURIComponent('{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"grouphub_short_title","filter":["' + encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.shortTitle) + '"]}'));
                }

                else if (granularity == 'authorMessages') {
                    var userName = document.getElementsByClassName('search-granularity')[0][document.getElementsByClassName('search-granularity')[0].selectedIndex].innerText;
                    //var userName = document.getElementsByClassName('search-granularity')[0].selectedOptions[0].innerText;
                    aggResults = encodeURIComponent(encodeURIComponent('{"type":"indexName","filter":["' + selectedCommunity + '"]},{"type":"author","filter":["' + userName + '"]} '));
                }

                window.location = "/t5/forums/searchpage/tab/message/search#!/search?searchString=" + encodeURIComponent(searchVariable) + "&from=0&sortby=_score&orderBy=desc&pageNo=1&aggregations=%255B" + aggResults + "%255D&uid=a7f8ff43-b37c-11e9-ad2e-06908fe445c6&resultsPerPage=10&exactPhrase=&withOneOrMore=&withoutTheWords=&pageSize=10&category=" + variables.autocompleteCallVariables.category;
            }
            //window.open(`${settings.redirectionLink}?searchString=${searchVariable}`, '_self');
        } else {
            document.getElementById('search-box-search').value = document.getElementById('search-box-autocomplete').value;
            document.getElementById('hit-me').click();
        }
    };

    useEffect(() => {
        document.body.classList.add('su__autocomplete-running');
    });
    /**
     * Initialising search call
     */
    useEffect(() => {
        variables.searchSource = 'autocomplete';
    }, []);

    /** it includes data from recent seach , autosuggestiona and hits */
    let [tempData, setTempData] = useState([]);

    useEffect(() => {
        let tempDataInner = [];
        if (granularity == 'user' || granularity == 'message') {
            variables.users = true;
            if (autoCompleteResult && autoCompleteResult.length == 0) {
                autoCompleteResult.result = {
                    hits: [],
                    max_score: null,
                    total: 0
                }
            }
            if (autoCompleteResult && autoCompleteResult.length) {
                autoCompleteResult.result = {
                    hits: autoCompleteResult,
                    max_score: null,
                    total: autoCompleteResult.length
                }
            }
            autoCompleteResult && autoCompleteResult.length && tempDataInner.push(...autoCompleteResult.result.hits);
            if (variables.autocompleteCallVariables.searchString && autoCompleteResult && autoCompleteResult.result && autoCompleteResult.result.hits && autoCompleteResult.result.hits.length) {
                setShowAutoCompleteResult(true);
                setIsComponentVisible(true);
            }
        } else {
            autoCompleteResult && autoCompleteResult.recentSearchHistory &&
                autoCompleteResult.recentSearchHistory.length != 0 && tempDataInner.push(...autoCompleteResult.recentSearchHistory);
            autoCompleteResult && autoCompleteResult.result && tempDataInner.push(...autoCompleteResult.result.hits);
            if (variables.autocompleteCallVariables.searchString && autoCompleteResult && autoCompleteResult.result && autoCompleteResult.result.hits && autoCompleteResult.result.hits.length) {
                setShowAutoCompleteResult(true);
                setIsComponentVisible(true);
            }
        }
        variables.autocompleteCallVariables.searchString && tempDataInner.push({ 'title': variables.autocompleteCallVariables.searchString, 'type': 'userTypedData' });
        if (autoCompleteResult && autoCompleteResult.searchClientSettings) {
            setChangeSearchDataOnArrowKey(
                Array(Math.max(0, tempDataInner.length - 1)).fill(null).map((_, i) => i)
            );
        }
        setTempData(tempDataInner);
    }, [autoCompleteResult]);

    /**
     * dispatches the search action.
     * event.currentTarget.value - is current user typed search strign
     */
    const dispatchSearchAction = (event) => {

        /**on first load , intialing the states */
        let tempDataInner = [];
        if (granularity == 'user' || granularity == 'notes') {
            autoCompleteResult && autoCompleteResult.length && tempDataInner.push(...autoCompleteResult);
        } else {
            autoCompleteResult && autoCompleteResult.recentSearchHistory &&
                autoCompleteResult.recentSearchHistory.length != 0 && tempDataInner.push(...autoCompleteResult.recentSearchHistory);
            autoCompleteResult && autoCompleteResult.result && tempDataInner.push(...autoCompleteResult.result.hits);
        }
        variables.autocompleteCallVariables.searchString && tempDataInner.push({ 'title': variables.autocompleteCallVariables.searchString, 'type': 'userTypedData' });
        if (tempDataInner && tempDataInner.length != 0) {
            setChangeSearchDataOnArrowKey(Array(Number(tempDataInner.length - 1)).fill(null).map((_, i) => i));
        }
        setTempData(tempDataInner);
        setPreviousAction('');
        /**  
         * control,esc,shift, spacebar, 4 arrow key ,Alt
         * There should be no search hit on these keys 
         * 
         * No search hit on Ctrl + char or Alt + char 
        */

        if (![17, 27, 16, 32, 37, 38, 39, 40, 18].includes(event.keyCode) && !event.ctrlKey && !event.altKey) {
            /** Show clear icon if search query exist  */
            event.currentTarget.value ? setShowClearIcons(true) : setShowClearIcons(false);
            /** KeyCode 13 is for Enter Key */
            if (settings.instantSearch && event.keyCode !== 13) {
                /**case when instant search is on and both apps are on same page */
                variables.autocompleteCallVariables.searchString = event.currentTarget.value;
                /** Dispatch search call  */
                variables.searchSource = 'autocomplete';
                dispatch(autocomplete.start(variables.autocompleteCallVariables));
                inputValue.current.value = variables.autocompleteCallVariables.searchString;
            } else {
                setShowAutoCompleteResult(true);
                if (event.keyCode === 13) {
                    if (tempData[changeSearchDataOnArrowKey[0]]?.href && previousAction) {
                        inputValue.current.value = tempData[tempData.length - 1].title;
                        var hrefValue = tempData[changeSearchDataOnArrowKey[0]].href;
                        window.open(hrefValue)
                        resultOpenNewTab(tempData[changeSearchDataOnArrowKey[0]], changeSearchDataOnArrowKey[0]);

                    } else {
                        if (openInNewTab != null && previousAction != ' ' && previousAction != undefined) {
                            window.open(openInNewTab, '_blank');
                            setOpenInNewTab(null);
                        } else {
                            /***on Enter press, redirect to search app and hide autocomplete result  */
                            settings.redirectionLink && redirection(event.currentTarget.value);
                        }
                    }
                    setShowAutoCompleteResult(false);
                } else {
                    if (event.currentTarget.value) {
                        /** 
                          * setting autoCompleteResult.result state to null before sending new search call
                          * So to clear old data in state , avoid flicker impact on autocomplete dropdown result
                          * if result is null - loading is added below autocomplete searchbox(means waiting for response)
                          */
                        autoCompleteResult.result = null;
                        /**  
                         * event.persist() to save event asynchronously 
                         * delay is added  to achieve debouncing so during that time event obj value should not be lost
                         * event.currentTarget.value consist of our search text query
                        */
                        event.persist();
                        let hasProp = event.currentTarget.hasOwnProperty('value');
                        let value = event.currentTarget.value;
                        /**
                         * setTimeout of 500 ms before hitting search api
                         * if we remove below setTimeout, search api hit will happen on every single keypress 
                         * this put significant load of backend resources
                         * 
                         * So to avoid multiple hits , debounce is achived here
                         * when user take pause for atleast 500ms while typing , autoCompleteSearchCall function is called
                         */
                        clearTimeout(prevIdCall);
                        setPrevIdCall(setTimeout(() => { autoCompleteSearchCall(event, hasProp, value); }, 1000));
                    }
                    else {
                        /** keep autocomplete result suggestions hide when search query string is empty */
                        setShowAutoCompleteResult(false);
                    }
                }
            }
        } else if ([40].includes(event.keyCode)) {
            /** 40 keycode for down arrow ↓ */
            autoCompleteResult && autoCompleteResult.result?.hits.length && handleUpDownArrowFunctionality('down');
        } else if ([38].includes(event.keyCode)) {
            /** 38 keycode for up arrow ↑ */
            autoCompleteResult && autoCompleteResult.result?.hits.length && handleUpDownArrowFunctionality('up');
        }
    };

    const handleUpDownArrowFunctionality = (action) => {
        let data = changeSearchDataOnArrowKey;
        if (action == 'down' && previousAction) {
            data.push(data.shift());
        } else if (action == 'up' && previousAction) {
            data.unshift(data.pop());
        }

        if (tempData[data[0]] && tempData[data[0]].type && ['userTypedData', 'recentSearch', 'autoSuggestion'].includes(tempData[data[0]].type)) {
            inputValue.current.value = tempData[data[0]].title;
        } else {
            if (tempData[data[0]].highlight.TitleToDisplayString[0].includes('&amp;')) {
                tempData[data[0]].highlight.TitleToDisplayString[0] = tempData[data[0]].highlight.TitleToDisplayString[0].replace('&amp;', '&');
            }
            if (tempData[data[0]].highlight.TitleToDisplayString[0].includes('&#43;')) {
                tempData[data[0]].highlight.TitleToDisplayString[0] = tempData[data[0]].highlight.TitleToDisplayString[0].replace('&#43;', '+');
            }
            if (tempData[data[0]].highlight.TitleToDisplayString[0].includes('&#39;')) {
                tempData[data[0]].highlight.TitleToDisplayString[0] = tempData[data[0]].highlight.TitleToDisplayString[0].replace('&#39;', "'");
            }

            inputValue.current.value = tempData[data[0]].highlight.TitleToDisplayString[0] || tempData[data[0]].href;
            setOpenInNewTab(tempData[data[0]].href);
        }
        /** Move Focus to current highlighted result */
        tempData[data[0]].type != 'userTypedData' && document.getElementsByClassName('su__suggestions-list')[data[0]].focus();
        /**Move focus back to autocomplete app search box */
        document.getElementById('search-box-autocomplete').focus();
        setPreviousAction(action);
        setChangeSearchDataOnArrowKey([...data]);
    };

    const autoCompleteSearchCall = (e, hasProp, value) => {
        if (hasProp) {
            let check = 0;
            /** dispatch autocomplete search  */
            variables.autocompleteCallVariables.searchString = value;
            if ((variables.searchClientType == 11 || variables.searchClientType == 12) && !window.su_utm) {
                const intervalId = setInterval(() => {
                    check++;
                    if (((variables.searchClientType == 11 || variables.searchClientType == 12) && window.su_utm) || check > 3) {
                        clearInterval(intervalId);
                        performSearchCall();
                    }
                }, 60 * 100);
            } else {
                performSearchCall();
            }
        }

        function performSearchCall() {
            dispatch(autocomplete.start(variables.autocompleteCallVariables));
        }
    };

    useEffect(() => {
        inputValue.current.focus();
        /**Set changeSearchDataOnArrowKey based on search results  */
        if (autoCompleteResult && autoCompleteResult.searchClientSettings) {
            setChangeSearchDataOnArrowKey(Array(Number(autoCompleteResult.searchClientSettings.autoComplete)).fill(null).map((_, i) => i));
        }
    }, [autoCompleteResult && autoCompleteResult.searchClientSettings && autoCompleteResult.searchClientSettings?.autoComplete]);

    /**
     *  autocomplete gza function
    */
    const resultOpenNewTab = (item, index) => {
        setIsComponentVisible(false);
        window.analyticsRecordAlready = true;
        gza('search', {
            'searchString': variables.autocompleteCallVariables.searchString,
            'result_count': autoCompleteResult.result.hits.length,
            'page_no': 0,
            'uid': variables.autocompleteCallVariables.uid,
            'filter': [],
            'conversion': [{
                rank: (index + 1),
                url: item['href'],
                subject: item['highlight']['TitleToDisplayString'][0] || '',
                es_id: item['sourceName'] + '/' + item['objName'] + '/' + encodeURIComponent(item['_id']),
                sc_analytics_fields: item['trackAnalytics']
            }]
        });
    };

    return (
        <section className="su__w-100 su__search_section">
            <div className="su__searchbox-component">
                <form autoComplete="off" id="searchForm" className="su__search-forms su__m-0 su__padding-autocomplete-custom" onSubmit={(e) => { e.preventDefault(); }}>
                    <div ref={ref} className="su__form-block su__w-100 su__position-relative">
                        <div className="su__radius-2 su__d-flex su__position-relative">
                            <input ref={inputValue} id="search-box-autocomplete" className="su__input-search su__w-100 su__su__font-14 su__text-black  su__border-none su__radius-2 su__pr-60" type="input" placeholder={placeholder} onKeyUp={(e) => dispatchSearchAction(e)} />
                            <AutoCompleteClearIcon showClearIcons={showClearIcons} setShowAutoCompleteResult={setShowAutoCompleteResult} setShowClearIcons={setShowClearIcons} autocompleteSearchbox={settings.autocompleteSearchbox} />
                            <AutoCompleteSearchIcon redirection={redirection} />
                        </div>
                        <AutoCompleteLoading isResultExist={autoCompleteResult && autoCompleteResult.result} searchString={inputValue.current?.value} />
                        {isComponentVisible && (!showAutoCompleteResult ?
                            null :
                            <AutoSuggestion comment={comment} comments={comments} askQuestionUrl={window.location.origin + '/' + askQuestionUrl} askQuestion={askQuestion} changeUsers={changeUsers} changeNoResultFound={changeNoResultFound} changeTotalResults={changeTotalResults} communitySuggestions={communitySuggestions} changePrivateMessage={changePrivateMessage} granularity={granularity} selectedCommunity={selectedCommunity} openInNewTab={openInNewTab} resultOpenNewTab={resultOpenNewTab} currentSearchString={inputValue.current.value} autoCompleteResult={autoCompleteResult} dataType={tempData[changeSearchDataOnArrowKey[0]] && tempData[changeSearchDataOnArrowKey[0]].type ? 'recentSearch' : 'autosuggest'} component="autocomplete" >
                                <RecentSearch position="aboveAllResult" redirection={redirection} />
                                <AutoCompleteResultIcon position="icon" />
                                <AutoCompleteResultTitle position="result" />
                                <AutoCompleteResultMetadata position="result" />
                                {/*   send position ->  belowAllResult to set component below all autocomplete results  */}
                                {/*   send position ->  aboveAllResult to set component above all autocomplete results  */}
                            </AutoSuggestion>)}
                    </div>
                </form>
            </div>
        </section>
    );
};

export default AutocompleteApp;