import React from 'react';
import Icons from '../../../assets/svg-icon/svg';
import IconColors from '../../../IconColors';
const AutoCompleteSearchIcon = (props) => {
    try {
        let { redirection } = props;
        return (
            <div className="su__btn su__search_btn su__animate-zoom su__flex-vcenter su__position-absolute su__zindex su__bg-transparent su__rtlleft">
                <button aria-label="Search" type="button" className="su__btn su__bg-transparent" onClick={() => { redirection() }}>
                    <svg className="su__search-icons" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1216 832q0-185-131.5-316.5t-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5 316.5-131.5 131.5-316.5zm512 832q0 52-38 90t-90 38q-54 0-90-38l-343-342q-179 124-399 124-143 0-273.5-55.5t-225-150-150-225-55.5-273.5 55.5-273.5 150-225 225-150 273.5-55.5 273.5 55.5 225 150 150 225 55.5 273.5q0 220-124 399l343 343q37 37 37 90z"></path>
                    </svg>
                </button>
            </div>

        );
    } catch (e) {
        console.log('Error in Search Icon component', e);
        return (<div></div>);
    }
};

export default React.memo(AutoCompleteSearchIcon);