export default {
  SEARCHCALL:'Search Call',
  ADVANCEDSEARCH:'Advanced Search',
  RESETYOURCHANEGES:'Are you sure you want to reset your changes?',
  WITHTHEEXACTPHRASE:'With the exact phrase',
  WITHONEORMOREWORDS:'With one or more words',
  WITHOUTTHEWORDS:'Without the words',
  WILDCARDSEARCH:'With the wildcard search',
  REFINESEARCH:'Refine Search',
  COPYCLIPBOARD:'Copy to clipboard',
  BOOKMARKALREADYEXISTS:'Bookmark with same name already exists',
  SAVEBOOKMARK:'Save Bookmark',
  SUBMIT_DISABLED:'Submit button disabled',
  SUBMIT:'Submit button',
  COPIED:'Copied',
  SAVEASBOOKMARK:'Save as Bookmark',
  TITLEFORTHISSEARCH:'Title for this search',
  SHOWBOOKMARK:'Show Bookmark',
  SAVEDBOOKMARKS:'Saved Bookmarks',
  REMOVEBOOKMARK:'Remove Bookmark',
  LANGUAGE:'Language',
  RELEVANCE:'Relevance',
  CREATEDDATE:'Created Date',
  LISTVIEW:'List View',
  ALLCONTENT:'All Content',
  CONTENTSOURCE:'Content source',
  NARROWYOURSEARCH:'Narrow your search',
  DIDYOUMEAN:'Did you mean',
  SHOWINGPAGE:'Showing page',
  SHOWING:'Showing',
  SHOWINGTOPRESULTFROMPREVIOUSSEARCHES:'Showing Top results from previous searches',
  CLEARALL:'Clear all',
  AUTOLEARNING:'Auto Learning',
  SEARCHTIPS:'Search Tips',
  CUSTOMIZE:'Customize',
  CUSTOMIZED:'Customized',
  ENTER_JUST_A_FEW_KEYWORDS:'Enter just a few key words related to your question or problem',
  ADD_KEYWORDS_TO_REFINE_SEARCH:'Add Key words to refine your search as necessary',
  DO_NOT_USE_PUNCTUATION:'Do not use punctuation',
  SEARCH_IS_NOT_CASE_SENSITIVE:'Search is not case sensitive',
  AVOID_NON_DESCRIPTIVE_FILLER_WORDS:'Avoid non-descriptive filler words like "how", "the", "what", etc.',
  IF_YOU_DO_NOT_FIND_WHAT_YOU_ARE_LOOKING:'If you do not find what you are looking for the first time,reduce the number of key words you enter and try searching again.',
  MINIMUM_SUPPORTED_INTERNET_EXPLORER:'Minimum supported Internet Explorer version is IE9',
  GIVEFEEDBACK:'Give Feedback',
  AUTOTUNED:'Auto tuned',
  BOOSTED:'Boosted',
  SIMILARSEARCHES:'Similar Searches',
  MORERESULTS:'More Results',
  RECOMMENDATIONS:'Recommendations',
  USEFULARTICLES:'Useful Articles',
  CLICK_HERE_TO_CLEAR_FILTERS_AND_PERFORM_A_FRESH_SEARCH:'Click here to clear filters and perform a fresh search',
  SORRY_NO_RESULTS_FOUND:'Sorry no results found',
  SHOWING_RESULTS_USING_SOME_OF_YOUR_RECENT_SEARCH_TERMS:'Showing results using some of your recent search terms',
  Clear :'Clear',
  reset_changes:'Are you sure you want to reset your changes?',
  su_no:'No',
  su_yes:'Yes',
  advance_search:'Advance search',
  edit_page:'Edit Page Layout',
  rearrange_facets:'Rearrange Facets',
  facet_still_visible: 'This Facet is still visible on the search page due to auto learning',
  facet_not_visible:'This Facet cannot be hidden on the search page due to auto learning',
  preselect_tab:'Pre Select a Tab',
  all_content:'All Content',
  search_result:'Search Result',
  search:'Search',
  answered:'Answered',
  created_date:'Created Date',
  su_true:'true',
  cancel:'Cancel',
  apply:'Apply',
  filter:'Filter',
  thanks_response:'Thank you for your response!',
  less:'Less',
  more:'More',
  sort_by:'Sort By',
  custom:'Custom',
  alphabatical_order:'Alphabetically (a-z)',
  alphabetical_opposite:'Alphabetically (z-a)',
  count_descending:'Count (desc)',
  count_ascending:'Count (asc)',
  positive_feedback : ' Positive feedback button for SU gpt response',
  positive_given:'Positive feedback given successfully',
  negative_given:'Negative feedback given successfully',
  negative_feedback :'Negative feedback button for SU gpt response',
  Accurate :'Accurate',
  ongpt:"You are currently on SU GPT widget",
  Comprehensive:'comprehensive',
  show_less:'Show less',
  show_more:'Show More',
  page_top:'Page Top',
  was_helpful:'Was above result helpful',
  bookmark:'Bookmark',
  bookmarks:'Bookmarks',
  saved_bookmarks_and_result:'Saved Bookmarks and Results',
  oops:'Oops',
  nothing_here:'There is nothing here. Zilch. Return to search',
  create_first:'and create your first bookmark ',
  save_first:'and save your first result ',
  remove_bookmark:'Remove Bookmark',
  show_bookmark :'Show Bookmark',
  grid_view:'Grid View',
  kudos:'Kudos',
  replies:'Replies',
  views:'Views',
  loading:'Loading',
  recommended_articles:'Recommended Articles',
  results_perpage:'Results per page',
  su__save:'Save',
  add_bookmark:'Add Bookmark',
  search_here:'Search here',
  give_search_feedback :'Give search Feedback',
  tips:'Tips',
  settings:'Settings',
  search_tips:'Search Tips',
  similar_searches:'Similar Searches',
  show_all:'Show all',
  see:'See',
  moreInSmall:'more',
  results:'results',
  preview:'Preview',
  seconds:'seconds',
  reset:'Reset',
  autolearning_facet_preselect:'Turn on this option to enable Auto Learning as well as Facet Pre Selection',
  skip_to_main_content:'Skip to Main Content',
  selected: 'Selected',
  heading_level_2: 'Heading level 2',
  results_available: 'Results Available',
  close_popup: 'Click to close popup',
  checkboxes: 'Checkboxes',
  slide_left: 'Slide left',
  slide_right: 'Slide right',
  pagination: 'Pagination',
  page: 'Page',
  bottom_navigation: 'Bottom Navigation',
  suggestions_found: 'Suggestions Found',
  show: 'Show',
  hide: 'Hide',
  remove: 'Remove',
  clear: 'Clear',
  select_language: 'Select Language',
  sort_by_label: 'Sort By Label',
  avoid_words: 'Avoid non-descriptive filler words',
  like: 'like',
  how: 'how',
  the: 'the',
  what: 'what',
  etc: 'etc',
  email_text: 'Enter email Id',
  invalid_email: 'Invalid Email Address',
  say_more: 'Would you like to say more ?',
  disabled_text: 'Disabled',
  not_found: 'Not Found',
  rating: 'Rating',
  Save_for_Later: 'Save for Later',
  Unsave: 'Unsave',
  Results : 'Results',
  Result_Unsaved : 'Result Unsaved',
  Result_Saved : 'Result Saved',
  Limit_Reached_Please_delete_to_save_new_results :'Limit Reached! Please delete to save new results',
  submit: 'Submit',
  retry: 'Retry'
};