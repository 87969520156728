export default {
  SearchCrossIcon : '#757575',
  SearchIcon:'#2b86ce',
  AdvanceSearchIconFill:'#919bb0',
  AdvanceSearchEnabledIconFill:'#3a8cf3',
  AdvanceSearchCrossIcon:'#919bb0',
  AutoCompleteClearIcon:'#757575',
  AutoCompleteSearchIcon:'#333',
  AutoTunesvgFill:'#8b8b8b',
  boostedSvgFill:'none',
  SaveBookmarksIcon:'#dddddd',
  SaveBookmarksCrossIcon:'#fff',
  ListBookmarksEmptyIcon:'#919bb0',
  ListBookMarksNotEmptyIcon:'#187ef2',
  ListBookmarksInModalIcon:'#fff',
  ListBookmarksCrossIcon:'#fff',
  ToggleViewGridIcon:'#919bb0',
  SettingsGearIcon:'#fff',
  SettingseditLayoutIcon:'#ffffff',
  SettingsSearchTipIcon:'#FF9800',
  FilterListFacetSearchIcon:'#59BEFE',
  FilterListFacetSearchCloseIcon:'#59BEFE',
  FacetPreferenceCrossIcon:'#333' ,
  FacetPreferenceShow_HideEye:'#292929',
  FacetPreferenceSaveBookmark:'#0082cacc',
  mobileFacetsCloseIcon:'#fff',
  FeaturedSnippetThumbsup_down:'#3E384A',
  FeaturedSnippetSvgFill:'#000000',
  FeatureSnippetSvgPlayGrey:'#CFCFCF',
  ScrollToTopIcon:'#fff',
  KnowledgeGraphThumbsup_down:'#fff',
  SearchResultFeedbackCloseIcon:'#000000',
  SearchResultFeedbackViewMore:'#919bb0',
  PreviewIconFill:'#57575c',
  SolvedsvgFill:'#5bb543',
  UnderConstructionsvgFill:'#008fd3',
  StickyFacetsSvgFill:'#919bb0',
  autolearn_svg_fill :"#58585A",
  search_tip_svg_fill:"#919bb0",
  settings_svg_fill:"#58585A",
  saved_result_fill:"#1770D4"

 
};